import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import emailjs from "@emailjs/browser";
import Header from "./headers";
import Whatapp from "../cmp/whatapp";
import Footer from "./Footer";
import "../cmp/style.css";

const Contactus = () => {
  const form = useRef();
  const [message, setMessage] = useState(""); // State for success/error message

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ct0mu0x",
        "template_lm9hzxa",
        form.current,
        "7rMYiyYjykrXlZ4Pm"
      )
      .then(
        (result) => {
          console.log(result.text);
          setMessage("Message sent successfully");
          resetForm();
        },
        (error) => {
          console.log(error.text);
          setMessage("Message sending failed");
        }
      );
  };
  const resetForm = () => {
    form.current.reset(); // Reset the form
    // Show success message for 5 seconds
    setTimeout(() => {
      setMessage("");
    }, 5000);
  };

  // Use useEffect to clear the message state after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage("");
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [message]);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts or pathname changes
  }, [pathname]);
  return (
    <>
      <Header />

      <div className="container-fluid home-brige d-flex flex-column align-items-center justify-content-center mb-4">
        <div className="">
          <h1 className="text-white display-3 fw-semibold">Contact us</h1>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item text-white ">
                <a href="#" className="text-white fs-5">
                  Home
                </a>
              </li>
              <li
                class="breadcrumb-item active text-white fs-5"
                aria-current="page"
              >
                Contact us
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="container py-5">
        <h1 className="text-center mb-5">Our Contacts Information</h1>

        <div className="row mb-5">
          <div className="col-md-4 border py-2">
            <div className="row">
              <div className="col-3 text-center mt-3">
                <i class="fa fa-map-marker fs-1"></i>
              </div>

              <div className="col-9">
                <h4>Our Address</h4>
                <p>
                  {" "}
                  #114 Sharada Kuteera,
1st cross 2nd main K K Layout,
Papareddy Pallya, Nagarabhavi 2nd Stage,Bangalore-560072
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 border py-2">
            <div className="row">
              <div className="col-3 text-center mt-3">
                <i class="fa fa-phone fs-1"></i>
              </div>

              <div className="col-9">
                <h4>Contact Number</h4>
                <p className="m-0"> Mobile No:- +91 9606142909, <br></br>+91 8904176751</p>
              </div>
            </div>
          </div>

          <div className="col-md-4 border py-2">
            <div className="row">
              <div className="col-3 text-center mt-3">
                <i class="fa fa-envelope fs-1"></i>
              </div>

              <div className="col-9">
                <h4>Email Id</h4>
                <p>myjyothishihealerhypnotist@gmail.com</p>
              </div>
            </div>
          </div>
        </div>

        <div className="shadow py-5">
          <h1 className="text-center mb-4">Get In Touch</h1>
          {message && <div className="success-message">{message}</div>}
          <form ref={form} onSubmit={sendEmail} autoComplete="off">
            <div className="row px-5 con-res">
              <div className="col-md-6">
                <div class="input-group mb-3">
                  <span class="input-group-text px-4" id="basic-addon1">
                    <i className="fa fa-user fs-5"></i>
                  </span>
                  <input
                    type="text"
                    class="form-control  py-3"
                    placeholder="Your Name"
                    aria-label="Username"
                    id="name"
                    name= "from_name"
                    required
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div class="input-group mb-3">
                  <span class="input-group-text px-4" id="basic-addon1">
                    <i className="fa fa-envelope fs-5"></i>
                  </span>
                  <input
                    type="email"
                    class="form-control  py-3"
                    placeholder="Email id"
                    aria-label="Username"
                    id="email"
                    name="user_email"
                    required
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div class="input-group mb-3">
                  <span class="input-group-text px-4" id="basic-addon1">
                    <i className="fa fa-map fs-5"></i>
                  </span>
                  <input
                    type="text"
                    class="form-control py-3"
                    placeholder="City"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    id="city"
                    name="user_city"
                    required
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div class="input-group mb-3">
                  <span class="input-group-text px-4" id="basic-addon1">
                    <i className="fa fa-phone fs-5"></i>
                  </span>
                  <input
                    type="tel"
                    class="form-control  py-3"
                    placeholder="Mobile No."
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    id="email"
                    name="user_mobile"
                    pattern="[0-9]{10}"
                    title="Please enter a 10-digit mobile number"
                    required
                  />
                </div>
              </div>

              <div className="col-md-12 mb-4">
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    placeholder="Leave a comment here"
                    id="floatingTextarea"
                    name="message"
                    
                    required
                    style={{ height: "200px" }}
                  ></textarea>
                  <label for="floatingTextarea">Comments</label>
                </div>
              </div>

              <div className="col-md-12 text-center">
                <button type="submit" className="btn btn-success py-2 fs-5">
                  Send Me Message
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* <div className="container-fluid">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3886.2744070577855!2d77.54376737510987!3d13.081787612452818!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDA0JzU0LjQiTiA3N8KwMzInNDYuOCJF!5e0!3m2!1sen!2sin!4v1700920232316!5m2!1sen!2sin"
          width="100%"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div> */}
      <Whatapp />
      <Footer />
    </>
  );
};

export default Contactus;
