import Accordion from 'react-bootstrap/Accordion';
import './style.css'
function BasicExample() {
  return (
    <Accordion >
      <Accordion.Item eventKey="0">
        <Accordion.Header><img src='astroimg1.jpg' alr='' className='px-3 svg-image' />Astrology</Accordion.Header>
        <Accordion.Body>
        Astrology services available here as per your birth chart or Prashna Shastra with your Mahadasha and antardasha and Nadi Shastra with calculations of all techniques.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header><img src='vastu1.jpg' alr='' className='px-3 svg-image' />Vasthu</Accordion.Header>
        <Accordion.Body>
        Our consultants will come to your site, house, shop, office anywhere and find out the problems and suggest the solutions.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header><img src='healing1.jpg' alr='' className='px-3 svg-image' />Healing</Accordion.Header>
        <Accordion.Body>
        Healing with salt therapy and distance healing also there by drawing power from cosmic and transfer to interested person .Heal all the problem whether it is bad Karma and physical, mental and financial problems.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header><img src='Hypnosis1.jpg' alr='' className='px-3 svg-image' />Hypnosis</Accordion.Header>
        <Accordion.Body>
        Teach to conscious mind which makes the person to achieve anything in the life, very good to students and anything to achievers, also get free from chronic diseases and phycological problems.
        </Accordion.Body>
      </Accordion.Item>


      <Accordion.Item eventKey="4">
        <Accordion.Header><img src='mudra1.jpg' alr='' className='px-3 svg-image' />
        Mudras
        </Accordion.Header>
        <Accordion.Body>
        Will teach here for health problems, diseases, and to maintain good health.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default BasicExample;