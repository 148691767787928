import React from 'react';
import '../cmp/style.css'
class CallIcon extends React.Component {
    constructor() {
        super();
        this.state = {
            phoneNumber: '9606142909',
        };
    }

    handleCallClick = () => {
        if (this.state.phoneNumber) {
            window.open(`tel:${this.state.phoneNumber}`);
        } else {
            console.error("Phone number is undefined or empty");
        }
    };



    handleWhatsAppClick = () => {
        const whatsappUrl = `https://wa.me/${this.state.phoneNumber}`;
        window.open(whatsappUrl, '_blank');
    };


    render() {




        return (
            <div>
                <button onClick={this.handleCallClick} style={{ backgroundColor: 'inherit', border: 'none' }}>
                    <i className='fa fa-phone me-1 fs-2 phone-icon' 
                    style=
                    {{ backgroundColor: '#E85B2A'
                    ,
                     borderRadius: '50%',
                      padding: '11px 12px',
                       color: 'white', 
                       cursor: 'pointer',
                       width:'60px',
                       height:'60px'
                        }}></i>
                </button>
            </div>
        );
    }
}

export default CallIcon;