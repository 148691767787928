import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import Header from "./headers";
import {
  Nav,
  Navbar,
  Container,
  Dropdown,
  DropdownButton,
  Carousel,
  Accordion,
} from "react-bootstrap";
import Carouselslider from "./Carousal";
import Countdown from "./Count";
import Accordingcom from "./According";
import { Link } from "react-router-dom";
import "../cmp/style.css";
import Whatapp from "../cmp/whatapp";
import Phone from "../cmp/phone";
import { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Footer from "./Footer";
const Home = () => {
  const form = useRef();
  const [message, setMessage] = useState(""); // State for success/error message

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_oskixvh",
        "template_knst93d",
        form.current,
        "TAcfZoldRBMFi_WI4"
      )
      .then(
        (result) => {
          console.log(result.text);
          setMessage("Message sent successfully");
          resetForm();
        },
        (error) => {
          console.log(error.text);
          setMessage("Message sending failed");
        }
      );
  };
  const resetForm = () => {
    form.current.reset(); // Reset the form
    // Show success message for 5 seconds
    setTimeout(() => {
      setMessage("");
    }, 5000);
  };

  // Use useEffect to clear the message state after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage("");
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [message]);

  const responsiveOptions = {
    0: {
      items: 1, // Show 1 item on screens smaller than 600px
    },
    600: {
      items: 2, // Show 2 items on screens between 600px and 1000px
    },
    1000: {
      items: 3, // Show 3 items on screens larger than 1000px
    },
  };

  return (
    <>
      <Header />

      <div className="container-fluid px-0">
        <Carousel data-bs-theme="dark">
          <Carousel.Item interval={3000}>
            <div className="bg-box">
              <img
                className="d-block w-100 img-res bg-img"
                src="bg-1.jpg"
                alt="First slide"
              />

              <Carousel.Caption className="slider-1">
                {/* <h1 className='display-3 fw-bold' data-aos="fade-right" data-aos-duration="2000" style={{color:'#5918B0'}}>Get Loan With Easy Steps</h1>
                                <p className='fs-5 fw-bold res-hide' style={{color:'black'}} data-aos="fade-left" data-aos-duration="2000">you can get any kind of loans like personal loan, business loan , home loan etc. <br />with easy process and assistance</p>
                                 <button className='btn btn-info px-4 fs-5' data-aos="fade-up"><a  className='text-decoration-none text-white' href='services' target='_blank'>Read More</a></button> */}
              </Carousel.Caption>
            </div>
          </Carousel.Item>
          {/* <Carousel.Item interval={3000}>
                        <img
                            className="d-block w-100 img-res"
                            src="bg-2.jpg"
                            alt="Second slide"
                        />
                       <Carousel.Caption className='slider-1'>
                                <h1 className='display-3 fw-bold' style={{color:'#5918B0'}} data-aos="fade-right" data-aos-duration="2000" >Get Loan With Easy Steps</h1>
                                <p className='fs-5 fw-bold  res-hide' style={{color:'black'}} data-aos="fade-left" data-aos-duration="2000">you can get any kind of loans like personal loan, business loan , home loan etc. <br />with easy process and assistance</p>
                                 <button className='btn btn-info px-4 fs-5' data-aos="fade-up"><a  className='text-decoration-none text-white' href='#'>Read More</a></button>
                            </Carousel.Caption>
                            </Carousel.Item> */}
          {/* <Carousel.Item interval={2000}>
                            <img
                                className="d-block w-100"
                                src="../Assets/Banner-3.png"
                                alt="Third slide"
                            />
                            <Carousel.Caption>
                                <h5>Third slide label</h5>
                                <p>
                                    Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                                </p>
                            </Carousel.Caption>
                        </Carousel.Item> */}
        </Carousel>
      </div>

      <div className="container fes-box bg-white py-4  shadow">
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex first">
              <div></div>
              <div
                className="auto-box"
                style={{
                  textAlign: "center",
                  fontSize: "25px",
                  padding: "8px 8px 16px 8px",
                }}
              >
                <h2 style={{ textDecorationLine: "underline" }}>
                  Welcome To My Jothishi
                </h2>
                <br></br>
                <p style={{ fontSize: "15px",  textAlign: 'justify' }}>
                  Jyotishya Acharya Shri Janardhan Kulkarni have studied Vedic
                  astrology, KP, Prashna Shastra gemmology at Bharatiya Vidya
                  Bhavan, Bengaluru. As per his Views “The job of an astrologer
                  is very good divine & to show the path really who are in
                  trouble.” Astrology is a divine code it is a secret of
                  Brahmanda, if we have to decode it, we should be pure hearted
                  and we all should have good behaviour and perform God with
                  related Mantras, Japa & to do the Puja Vrathas etc.
                </p>
              </div>
            </div>
          </div>

          {/* <div className="col-md-6">
            <div className="d-flex first">
              <div>
                <img src="fea-2.svg" alr="" className="px-3 svg-image" />
              </div>
              <div>
                <h2>Interior Designing</h2>
                <p>
                  Construction services also encompass renovating and existing
                  structures to update them change layout
                </p>
              </div>
            </div>
          </div> */}

          {/* <div className='col-md-4'>
                        <div className='d-flex first'>
                            <div>
                                <img src='fea-3.svg' alr='' className='px-3 svg-image' />
                            </div>
                            <div>
                                <h2>House
Renovation</h2>
                                <p>
                                This includes building homes, apartments, and housing units. It can involve single-family homes and more.
                                </p>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>

      <div className="bg-light py-5">
        <div className="container mb-5">
          <div className="row">
            <div className="col-md-6">
              <img src="about.jpg" style={{ width: "100%", height: "650px" }} />
            </div>
            <div className="col-md-6">
              <p>About Us</p>
              <h3 className="display-5 fw-bold">
                Jyotishya Acharya Shri Janardhan Kulkarni
              </h3>
              <p style={{textAlign: 'justify' }}>
                He have studied Vedic astrology, KP, Prashna Shastra gemmology
                at Bharatiya Vidya Bhavan, Bengaluru.
              </p>
              <p style={{textAlign: 'justify' }}>
                As per his Views “The job of an astrologer is very good divine &
                to show the path really who are in trouble.”
              </p>
              <p style={{textAlign: 'justify' }}>
                Astrology is a divine code it is a secret of Brahmanda, if we
                have to decode it, we should be pure hearted and we all should
                have good behaviour and perform God with related Mantras, Japa &
                to do the Puja Vrathas etc.
              </p>
              <p style={{textAlign: 'justify' }}>
                One who speaks truth adapt in three wings of Astrology learned
                in law of Dharma one who follows right behaviour, who has Mantra
                Siddhi always engaged in worship of God becomes an Astrologer.
                One who become astrologer should not tell lies things he should
                predict accurate should tell always truth. His prediction not
                depend upon money should be seen the all the Clients are equal.
              </p>
              <div className="d-flex align-items-center about-more">
                <div className="me-5 about-box">
                  <button className="btn btn-info text-white px-4 py-3 fs-5 mb-5">
                    <Link
                      to="/about-us"
                      className="text-decoration-none text-white">
                      About More
                    </Link>
                  </button>
                </div>
                <div className="d-flex">
                  <Phone />
                  <div className="ms-4">
                    <h4>Have Any Question</h4>
                    <p className="fw-bold">+91 9606142909</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6">
            {/* <h4>FREQUENTLY ASK QUESTIONS</h4> */}
            <h2
              className="display-4 mb-4 fw-bold"
              style={{ textDecorationLine: "underline" }}
            >
              Services
            </h2>
            <div>
              <Accordingcom />
            </div>
          </div>
          <div className="col-md-6">
            <img src="faq.png" style={{ width: "100%",  }} />
          </div>
        </div>
      </div>

      <div className="contact-bg py-5" style={{ }}>
        <div
          className="bg-white w-50 h-75 mob-res"
          style={{ position: "absolute", right: "20%" }}
        >
          <div className="row">
            <div className="col-md-4 ">
              <div
                className="px-3 py-4 quote-box"
                style={{
                  backgroundColor: "#E85B2A",
                  width: "100%",
                  height: "95.7%",
                }}
              >
                <h3 className="text-white">
                  You Get Free Consultation For Any Help
                </h3>
              </div>
            </div>
            <div className="col-md-8 py-4 ">
              <div className="py-3">
                <h3 className="text-center mb-4">Request A Quote</h3>
                <div>
                  {message && <div className="success-message">{message}</div>}
                  <form ref={form} onSubmit={sendEmail} autoComplete="off">
                    <div className="row px-3">
                      <div className="col-md-6">
                        <div class="form-floating mb-3">
                          <input
                            type="text"
                            class="form-control"
                            id="name"
                            name="from_name"
                            required
                            placeholder="name"
                          />
                          <label for="floatingInput">Your Name</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="form-floating mb-3">
                          <input
                            type="email"
                            class="form-control"
                            id="email"
                            name="user_email"
                            required
                            placeholder="name@example.com"
                          />
                          <label for="floatingInput">Email Id</label>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div class="form-floating mb-3">
                          <input
                            type="tel"
                            //  <!-- Use type="tel" for telephone numbers -->
                            class="form-control"
                            id="floatingInput"
                            placeholder="name"
                            name="user_mobile"
                            pattern="[0-9]{10}"
                            title="Please enter a 10-digit mobile number"
                            required
                          />
                          <label for="floatingInput">Mobile No.</label>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div class="form-floating mb-3">
                          <input
                            type="text"
                            class="form-control"
                            id="floatingInput"
                            name="user_city"
                            required
                            placeholder="name@example.com"
                          />
                          <label for="floatingInput">City</label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div class="form-floating mb-3">
                          <textarea
                            class="form-control"
                            placeholder="Leave a comment here"
                            id="floatingTextarea2"
                            name="message"
                            style={{ height: "130px" }}
                          ></textarea>
                          <label for="floatingTextarea2">Comments</label>
                        </div>
                      </div>

                      <div className="col-md-12 text-center">
                        <button type="submit" class="btn btn-primary">
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-dark py-5">
        <div className="container py-3">
          <h2 className="text-white text-center display-5 fw-bold mb-5">
            What Our Clients Say?
          </h2>
          <OwlCarousel
            items={3}
            className="owl-theme"
            loop
            nav
            margin={20}
            autoplay
            autoplayTimeout={5000}
            responsive={responsiveOptions}
          >
            <div className=" mb-4">
              <div class="card border-0 shadow-lg">
                <div className="w-25 text-center rounded-circle mx-auto mt-3">
                  <img src="test-2.jpg" className="rounded-circle" />
                </div>
                <div class="card-body text-center">
                  <p class="card-text fs-5">
                  Got accurate prediction. Good Astrologer.
Getting detailed analysis about horoscope and incidents.
                  </p>
                  <h5 class="card-title text-black">Vinay Kulkarni</h5>
                </div>
              </div>
            </div>

            <div className=" mb-4">
              <div class="card border-0 shadow-lg">
                <div className="w-25 text-center rounded-circle mx-auto mt-3">
                  <img src="test-3.jpg" className="rounded-circle" />
                </div>
                <div class="card-body text-center">
                  <p class="card-text fs-5">
                  Nice experience. I have taken for house vasthu purpose. Got good clarity for buying house. Also he explained my good time and bad times .....
                  </p>

                  <h5 class="card-title text-black">Prathi Kuttappa</h5>
                </div>
              </div>
            </div>

            <div className=" mb-4">
              <div class="card border-0 shadow-lg">
                <div className="w-25 text-center rounded-circle mx-auto mt-3">
                  <img src="test-4.jpg" className="rounded-circle" />
                </div>
                <div class="card-body text-center">
                  <p class="card-text fs-5">
                  Accurate prediction. Showing very food path. I have got remedies for many of my problems. By Guruji must visit.
                  </p>

                  <h5 class="card-title text-black">Vani Kulkarni b</h5>
                </div>
              </div>
            </div>

            <div className=" mb-4">
              <div class="card border-0 shadow-lg">
                <div className="w-25 text-center rounded-circle mx-auto mt-3">
                  <img src="test-5.jpg" className="rounded-circle" />
                </div>
                <div class="card-body text-center">
                  <p class="card-text fs-5">
                  One of the best Astrologer. They predicting very well
                  </p>

                  <h5 class="card-title text-black">Varun Shanbhog</h5>
                </div>
              </div>
            </div>

            <div className=" mb-4">
              <div class="card border-0 shadow-lg">
                <div className="w-25 text-center rounded-circle mx-auto mt-3">
                  <img src="test-1.jpg" className="rounded-circle" />
                </div>
                <div class="card-body text-center">
                  <p class="card-text fs-5">
                  Sir will give best  result
                  </p>

                  <h5 class="card-title text-black">Sandesh Ballari</h5>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
      <Whatapp />
      <Footer />
    </>
  );
};

export default Home;
