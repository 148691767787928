import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "./headers";
import Footer from "./Footer";
import Whatapp from "../cmp/whatapp";
import "../cmp/style.css";
import "../cmp/style1.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Projects = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts or pathname changes
  }, [pathname]);
  const responsiveOptions = {
    0: {
      items: 1, // Show 1 item on screens smaller than 600px
    },
    600: {
      items: 2, // Show 2 items on screens between 600px and 1000px
    },
    1000: {
      items: 3, // Show 3 items on screens larger than 1000px
    },
  };
  return (
    <>
      <Header />
      <div className="container-fluid home-brige d-flex flex-column align-items-center justify-content-center mb-4">
        <div className="">
          <h1 className="text-white display-3 fw-semibold"> Our Gallery</h1>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item text-white ">
                <a href="#" className="text-white fs-5">
                  Home
                </a>
              </li>
              <li
                class="breadcrumb-item active text-white fs-5"
                aria-current="page"
              >
                {" "}
                Our Gallery
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className=" position-relative">
                <img
                  src="ceilling.jpg"
                  style={{ width: "100%", height: "356px" }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100  position-relative">
                <img
                  src="painting.jpg"
                  style={{ width: "100%", height: "356px" }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100  position-relative">
                <img
                  src="renovation.jpg"
                  style={{ width: "100%", height: "356px" }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100    position-relative">
                <img
                  src="plots.jpg"
                  style={{ width: "100%", height: "356px" }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100  position-relative">
                <img
                  src="plots2.jpg"
                  style={{ width: "100%", height: "356px" }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100  position-relative">
                <img
                  src="plots1.jpg"
                  style={{ width: "100%", height: "356px" }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100  position-relative">
                <img
                  src="plots3.jpg"
                  style={{ width: "100%", height: "356px" }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100  position-relative">
                <img
                  src="plots4.jpg"
                  style={{ width: "100%", height: "356px" }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100  position-relative">
                <img
                  src="plots5.jpg"
                  style={{ width: "100%", height: "356px" }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100  position-relative">
                <img
                  src="plots6.jpg"
                  style={{ width: "100%", height: "356px" }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100  position-relative">
                <img
                  src="plots7.jpg"
                  style={{ width: "100%", height: "356px" }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100  position-relative">
                <img
                  src="plots8.jpg"
                  style={{ width: "100%", height: "356px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Whatapp />
      <Footer />
    </>
  );
};

export default Projects;
