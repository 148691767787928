import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "./headers";
import Whatapp from "../cmp/whatapp";
import Footer from "./Footer";
import "../cmp/style.css";

const Services = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts or pathname changes
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="container-fluid home-brige d-flex flex-column align-items-center justify-content-center mb-4">
        <div className="">
          <h1 className="text-white display-3 fw-semibold">Services</h1>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item text-white ">
                <a href="#" className="text-white fs-5">
                  Home
                </a>
              </li>
              <li
                class="breadcrumb-item active text-white fs-5"
                aria-current="page"
              >
                Services
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="container py-5">
       
        <h1 className="text-center fw-bold mb-5">The Best Service For You</h1>

        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="shadow p-3 py-5">
              <div className="d-flex justify-content-around mb-3">
                <div className="cor-img">
                  <img src="astroimg1.jpg" />
                </div>
                <h3 className="text-center">
                Astrology
                </h3>
                <p className="fs-4" style={{ color: "#B2B2B2" }}>
                  01
                </p>
              </div>
              <p className="px-4 fs-5" style={{textAlign: 'justify' }}>
              Astrology services available here as per your birth chart or Prashna Shastra with your Mahadasha and antardasha and Nadi Shastra with calculations of all techniques.
              </p>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="shadow p-3 py-5">
              <div className="d-flex justify-content-around mb-3">
                <div className="cor-img">
                  <img src="vastu1.jpg" />
                </div>
                <h3 className="text-center">
                Vasthu
                </h3>
                <p className="fs-4" style={{ color: "#B2B2B2" }}>
                  02
                </p>
              </div>
              <p className="px-4 fs-5" style={{textAlign: 'justify' }}>
              Our consultants will come to your site, house, shop, office anywhere and find out the problems and suggest the solutions.
              </p>
            </div>
          </div>

          <div className='col-md-4 mb-4'>
            <div className='shadow p-3 py-5'>
              <div className='d-flex justify-content-around mb-3'> 
                <div className='cor-img'><img src='healing1.jpg' /></div>
                  <h3 className='text-center'>Healing</h3>
                    <p className='fs-4' style={{color:'#B2B2B2'}}>03</p>         
                  </div>
                  <p className='px-4 fs-5' style={{textAlign: 'justify' }}>
                  Healing with salt therapy and distance healing also there by drawing power from cosmic and transfer to interested person .Heal all the problem whether it is bad Karma and physical, mental and financial problems.                                </p>
                </div>
              </div>

          {/* <div className='col-md-4'>
                         <div className='shadow p-3 py-5'>
                           
                           <div className='d-flex justify-content-around mb-3'> 
                           <div className='cor-img'><img src='ser-4.svg' /></div>
                           <h3 className='text-center'>Architecture<br /> Design</h3>
                            <p className='fs-4' style={{color:'#B2B2B2'}}>04</p>
                           
                            </div>
                            <p className='px-4 fs-5'>
                            Construction services also encompass renovating and existing structures to update them change layout.
                                </p>
                         </div>

                     </div> */}

          <div className="col-md-4">
            <div className="shadow p-3 py-5">
              <div className="d-flex justify-content-around mb-3">
                <div className="cor-img">
                  <img src="Hypnosis1.jpg"  />
                </div>
                <h3 className="text-center">Hypnosis</h3>
                <p className="fs-4" style={{ color: "#B2B2B2" }}>
                  04
                </p>
              </div>
              <p className="px-4 fs-5" style={{textAlign: 'justify' }}>
              Teach to conscious mind which makes the person to achieve anything in the life, very good to students and anything to achievers, also get free from chronic diseases and phycological problems.              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="shadow p-3 py-5">
              <div className="d-flex justify-content-around mb-3 px-3">
                <div className="cor-img">
                  <img src="mudra1.jpg"/>
                </div>
                <h3 className="text-center">Mudrast</h3>
                <p className="fs-4" style={{ color: "#B2B2B2" }}>
                  05
                </p>
              </div>
              <p className="px-4 fs-5" style={{textAlign: 'justify' }}>
              Will teach here for health problems, diseases, and to maintain good health.
              </p>
            </div>
          </div>
          {/* 
                    <div className='col-md-4'>
                        <div className='shadow p-3 py-4'>

                            <div className='d-flex justify-content-around mb-3 px-3'>
                                <div className='cor-img'><img src='planning.png'  className='text-start'/>
                             <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati nesciunt cumque, tempora consequuntur quae error!</p>
                                
                                </div>
                                

                            </div>
                           
                        </div>

                    </div> */}

         
        </div>
      </div>
      <Whatapp />
      <Footer />
    </>
  );
};

export default Services;
