import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "./headers";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Whatapp from "../cmp/whatapp";
import "../cmp/style.css";
const Aboutus = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts or pathname changes
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="container-fluid home-brige d-flex flex-column align-items-center justify-content-center">
        <div className="">
          <h1 className="text-white display-3 fw-semibold">About Us</h1>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item text-white ">
                <a href="#" className="text-white fs-5">
                  Home
                </a>
              </li>
              <li
                class="breadcrumb-item active text-white fs-5"
                aria-current="page"
              >
                About us
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="container py-5">
        <div className="row">
          <div>
            <h1 className="mb-5 fw-bold text-center " style={{ color: "red" }}>
            Welcome To My Jothishi
            </h1>
          </div>
          <div className="col-md-5">
            <img src="ab.jpg" alt="pic" style={{ width: "100%" }} />
          </div>

          <div className="col-md-7">
            <h3 className="fw-bold">
            Jyotishya Acharya
            </h3>
            <p className="fs-5" style={{textAlign: 'justify' }}>
             Shri Janardhan Kulkarni have studied Vedic astrology, KP, Prashna Shastra gemmology at Bharatiya Vidya Bhavan, Bengaluru.
            </p>
            <p className="fs-5" style={{textAlign: 'justify' }}>
            As per his Views “The job of an astrologer is very good divine & to show the path really who are in trouble.”
            </p>

            <p className="fs-5" style={{textAlign: 'justify' }}>
            Astrology is a divine code it is a secret of Brahmanda, if we have to decode it, we should be pure hearted and we all should have good behaviour and perform God with related Mantras, Japa & to do the Puja Vrathas etc.
            </p>

            <p className="fs-5" style={{textAlign: 'justify' }}>
            One who speaks truth adapt in three wings of Astrology learned in law of Dharma one who follows right behaviour, who has Mantra Siddhi always engaged in worship of God becomes an Astrologer. One who become astrologer should not tell lies things he should predict accurate should tell always truth. His prediction not depend upon money should be seen the all the Clients are equal.
            </p>
            <p className="fs-5" style={{textAlign: 'justify' }}>
            Acharya Ji has so many happy Clients which for which he has predicted accurate gave good simple remedies without taking any Monetary things. Many unhappy People in married life who having sufferings are suggested by him and performed remedies suggested accordingly, they are really happy still appreciating Acharya Ji and one of the lady always telling I will not come out from your Debt (Runa) in this birth, Likewise. That much Influence is made Acharya ji in their Lives.
            </p>
            <p className="fs-5" style={{textAlign: 'justify' }}>
            He showed a path by giving good solution who are you in very critical position of finance by saying simple remedies after that he saved their property which client was going to sell for his loan.           
          </p>
           
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-12">
          <p className="fs-5" style={{textAlign: 'justify' }}>
            Acharya Ji have predicted for so many people about job timing of marriage, marriage matching, critical health condition chronic health diseases litigation of property Court cases for such kind of so many problems. They are really happy by solving their problems by his prediction and remedies.            </p>
            <p className="fs-5" style={{textAlign: 'justify' }}>
            Apart from that Acharya Janardhan Kulkarni know occult science Healing and Hypnosis for an Ex: One student was lacking to score the marks and failed in SSLC preparatory exam by giving five sessions of hypnosis she scored 90% in the SSLC board exam. One engineering student depressed & use to sleep always, only one session of hypnosis changed his life towards the active and success now.            </p>
            <p className="fs-5" style={{textAlign: 'justify' }}>
            There are many stories in his astrological journey and healing hypnosis life made the person to get achieve anything in the life.            </p>
            <p className="fs-5" style={{textAlign: 'justify' }}>
            He want to solve everyone's problem who are coming to me to Seek Divine Help from Him.            </p>
            
          </div>
          <div className="text-center">
            <button className="btn btn-info text-white py-3 fs-5">
              <Link to="/contactus" className="text-decoration-none text-white">
                Know More Contact us
              </Link>
            </button>
          </div>
        </div>
      </div>
      <Whatapp />
      <Footer />
    </>
  );
};

export default Aboutus;
